import React from "react";
import CompetencesHeader from "./CompetencesHeader";
import CompetenceType from "./CompetenceType";

export default class Competences extends React.Component<CompetencesInterface> {


    getCustomStyle = (index: number): string => {
        return index == 0 ? "competence-first" : this.props.skills[index - 1].break ? "separator-row-after" : ""
    }

    render() {
        return (
            <div id={"competence"} className={"block"}>
                <h2>Compétences</h2>
                <div className="line-separator horizontal"></div>
                <div className={"block-content"}>
                    <CompetencesHeader/>
                    {
                        this.props.skills.map((skill: Skill, index: number) => {
                                if (skill.break) {
                                    return (
                                        <div id="separator-row" className="competence-row">
                                            <div className="row-first-column"></div>
                                            <div id="separator" className="flex">
                                                <div className="line-separator horizontal"></div>
                                            </div>
                                        </div>
                                    )
                                }
                                return <CompetenceType skill={skill} idRowStyle={this.getCustomStyle(index)}/>
                            }
                        )
                    }
                </div>
            </div>
        )
    }
}

interface CompetencesInterface {
    skills: Skill[]
}

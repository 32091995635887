import React from "react";

export default class CertificateEntry extends React.Component<CertificateEntryInterface> {


    private getStartDate() {
        return <div>{this.props.certificate.month} <span className="date-highlight">{this.props.certificate.year}</span>
        </div>;
    }

    getCurrentColor = (level: String) => {
        switch (level) {
            case "Préparation" :
                return "blue"
            case "Obtenue" :
                return "gold"
        }
    }

    private getTagColor(skillName: string) {
        if (skillName) {
            return "tag " + this.getCurrentColor(skillName);
        }
        return "tag red";
    }

    private getBorderColor(skillName: string) {
        if (skillName) {
            return "certificate-div certificate-div-" + this.getCurrentColor(skillName);
        }
        return "certificate-div";
    }

    render() {
        return (
            <div className={this.getBorderColor(this.props.certificate.state)}>
                <div style={{width: "-webkit-fit-content", margin: "4px auto"}}>
                    <img style={{width: "160px"}} src={this.props.certificate.image} />
                </div>
                <div style={{width:"80%", margin: "auto"}}>
                    <b style={{flexWrap: "wrap"}}>{this.props.certificate.name}</b>
                </div>
                <div className="line-separator horizontal" style={{margin: "6px auto", width: "40%"}}></div>

                {this.getStartDate()}

                <div style={{width: "-webkit-fit-content", margin: "4px auto"}}>
                    <div className={this.getTagColor(this.props.certificate.state)}>{this.props.certificate.state}</div>
                </div>

            </div>
        )
    }


}

interface CertificateEntryInterface {
    certificate: Certificate;
}

import React from "react";
import parse from "html-react-parser";

export default class ProfilHeader extends React.Component<ProfilHeaderInterface>{
    render() {
        return (
            <div>
                <div>
                    <h1>{this.props.informations.name}</h1>
                    <div className={"line-separator horizontal"}/>
                    <h2 className={this.props.informations.disponible ? "freelance" : "freelance-indispo"}>Freelance<br/>{this.props.informations.disponible ? "Disponible au " + this.props.informations.disponible : "Indisponible"}</h2>
                </div>
                <h2>{this.props.informations.poste ? parse(this.props.informations.poste.join("<br/>")) : null}</h2>
                <h2 id="exp-title"><b>{this.props.informations.experience}</b></h2>
            </div>
        )
    }
}

interface ProfilHeaderInterface {
    informations: Informations
}

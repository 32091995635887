import React from "react";
import ExperienceEntry from "../Experiences/ExperienceEntry";
import CertificateEntry from "./CertificateEntry";

export default class Certificates extends React.Component<CertificatesInterface> {
    render() {
        return (
            <div id={"certificat"} className="block">
                <h2>Certifications</h2>
                <div className="line-separator horizontal"></div>
                <div className="block-content">
                    {
                        this.props.certificates.map((entry) => <CertificateEntry certificate={entry} />)
                    }
                </div>
            </div>
        )
    }
}

interface CertificatesInterface {
    certificates: Certificate[],
}

import React from "react";
import parse from 'html-react-parser';

export default class ExperienceEntry extends React.Component<ExperienceEntryInterface> {

    static defaultProps : {
        isSubEntry: false
    }

    private getStartDate() {
        return <div>{this.props.experienceEntry.startMonth} <span className="date-highlight">{this.props.experienceEntry.startYear}</span></div>;
    }

    private getEndDate() {
        if(this.props.experienceEntry.endMonth != undefined && this.props.experienceEntry.endYear != undefined) {
            return <div>{this.props.experienceEntry.endMonth} <span className="date-highlight">{this.props.experienceEntry.endYear}</span></div>;
        }
        return null;
    }

    getCurrentColor = (level: String) => {
        switch (level) {
            case "REGULAR" :
                return "green"
            case "SOMETIMES" :
                return "blue"
            case "OLD" :
                return ""
        }
    }

    private getTagColor(skillName: string) {
        let skillItem = this.props.competences.find(entry => entry && entry.items.indexOf(skillName) != -1);
        if(skillItem) {
            return "tag " + this.getCurrentColor(skillItem.level);
        }
        return "tag red";
    }

    sortSkill = (a: string, b: string) : number => {
        let skillItemA = this.props.competences.find(entry => entry && entry.items.indexOf(a) != -1);
        let skillItemB = this.props.competences.find(entry => entry && entry.items.indexOf(b) != -1);

        if(!skillItemA) {
            return 1
        }
        if(!skillItemB) {
            return -1
        }

        if(skillItemA.level == skillItemB.level) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        }

        if(skillItemA.level == "REGULAR"){
            return -1
        }

        if(skillItemB.level == "REGULAR") {
            return 1
        }

        if(skillItemA.level == "SOMETIMES"){
            return -1
        }

        return 1


    }

    render() {
        return(
            <div className={this.props.isSubEntry ? "experience-subentry" : "experience-entry" }>
                <div className={this.props.isSubEntry ? "experience-subentry-date" : "experience-entry-date"}>
                    {this.getEndDate()}
                    {this.getStartDate()}
                </div>
                <div className="line-separator vertical"></div>
                <div className={this.props.isSubEntry ? "experience-subentry-content" : "experience-entry-content"}>
                    <h3>{this.props.experienceEntry.name}</h3>
                    <h4>{this.props.experienceEntry.title}</h4>

                    {this.props.experienceEntry.context ? <p className="indication-experience">{ parse(this.props.experienceEntry.context)}</p> : null }

                    <div className="tag-row">
                        {this.props.experienceEntry.skills ? this.props.experienceEntry.skills.sort(this.sortSkill).map((entry) => <div className={this.getTagColor(entry)}>{entry}</div> ) : null }
                    </div>

                    {this.props.experienceEntry.descriptions ? this.props.experienceEntry.descriptions.map((entry) => entry.startsWith("@isHTML") ? parse(entry.replace("@isHTML", "")) : <p>{entry}</p>) : null }

                    {this.props.experienceEntry.subreferences ? this.props.experienceEntry.subreferences.map((entry) => <ExperienceEntry experienceEntry={entry} isSubEntry={true} competences={this.props.competences}/>) : null }

                </div>


            </div>
        )
    }


}

interface ExperienceEntryInterface {
    experienceEntry: Reference;
    isSubEntry : boolean,
    competences: SkillItem[]
}

import React from "react";
import InterestEntry from "./InterestEntry";

export default class Interests extends React.Component<InterestInterface> {
    render() {
        return (
            <div id="personnalite" className="block">
                <h2 className="collapsible active">Centres d'intérêt</h2>
                <div className="line-separator horizontal"></div>
                <div className="block-content">
                    {this.props.interests.map(entry => <InterestEntry interest={entry}/>)}
                </div>
            </div>
        )
    }
}

interface InterestInterface {
    interests: Interest[]
}

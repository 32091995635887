import React from "react";
import CompetenceLevel from "./CompetenceLevel";

export default class CompetenceType extends React.Component<CompetenceTypeInterface> {

    static defaultProps = {
        idRowStyle: "",
    };

    render() {
        return (
            <div id={this.props.idRowStyle} className="competence-row">
                <div className="row-first-column">{this.props.skill.type}</div>
                {
                    this.props.skill.items.map((skill: SkillItem) => {
                            return <CompetenceLevel skillItem={skill}/>
                        }
                    )
                }
            </div>
        )
    }
}

interface CompetenceTypeInterface {
    skill: Skill,
    idRowStyle: string;
}

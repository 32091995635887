import React from "react";
import ProfilHeader from "./ProfilHeader";
import ProfilContact from "./ProfilContact";

export default class ProfilDescription extends React.Component<ProfilDescriptionInterface> {
    render() {
        return (
            <div id="profil-description">
                <ProfilHeader informations={this.props.informations}/>
                <ProfilContact contacts={this.props.informations.contact}/>
            </div>
        )
    }
}

interface ProfilDescriptionInterface {
    informations: Informations;
}

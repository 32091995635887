import React from "react";

export default class InterestEntry extends React.Component<InterestEntryInterface> {
    render() {
        return (
            <div className="personnalite">
                <div className={"display-icon " + this.props.interest.cssBackgroundClass}>
                    {this.props.interest.pictureUrl ?
                        <div className="svg-embed-icon">
                            <img src={this.props.interest.pictureUrl}/>
                        </div>
                        : <i className={"fa " + this.props.interest.picture}></i>}
                </div>
                <div className="label-personnalite"><span>{this.props.interest.name}</span></div>
            </div>
        );
    }
}

interface InterestEntryInterface {
    interest: Interest
}

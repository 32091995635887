import * as React from "react";
import './index.css'
import Profil from "./profil/Profil";

import {createRoot} from 'react-dom/client';
import Competences from "./comptences/competences";
import DataLoader from "./DataLoader";

const container = document.getElementById('body');
const root = createRoot(container);
root.render(
    <DataLoader/>
)

import React from "react";

export default class ProfilContact extends React.Component<ProfilContactInterface> {

    componentDidMount() {
        console.log(this.props.contacts)
    }

    render() {
        return(
            <div className="flex">
                <div className="line-separator vertical"></div>
                <div id="profil-contact-infos">
                    <div>
                        {this.props.contacts ? this.props.contacts.map(entry => {
                            return (
                                <div className="display">
                                    <div className="display-icon">
                                        {entry.image ?
                                            <i className={"fa" + (entry.imageBrands ? "-brands" : "") + " " + entry.image}></i> : entry.imageUrl ?
                                                <img src={entry.imageUrl} /> : null
                                        }
                                    </div>

                                        <div className="display-icon-text">
                                            {entry.link ? <a href={entry.link}>{entry.name}</a> : entry.name }
                                        </div>
                                </div>
                            )
                        }) : null }
                    </div>
                    <div id="button-download">
                        <button onClick={print} className="button">
                            <i className="fa fa-print"></i>Imprimer le CV
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

interface ProfilContactInterface {
    contacts: Contact[]
}

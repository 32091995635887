import React from "react";
import ExperienceEntry from "./ExperienceEntry";

export default class Experiences extends React.Component<ExperiencesInterface> {
    render() {
        return(
            <div id={this.props.cssId} className="block">
                <h2>{this.props.title}</h2>
                <div className="line-separator horizontal"></div>
                <div className="block-content">
                    {
                        this.props.experiences.map((entry) => <ExperienceEntry experienceEntry={entry} competences={this.props.skills}/>)
                    }
                </div>
            </div>
        )
    }
}

interface ExperiencesInterface {
    experiences: Reference[],
    skills: SkillItem[],
    title: string,
    cssId: string
}

import React from "react";

export default class ProfilImage extends React.Component {
    render() {
        return(
            <div id="profil-image">
                <img src="./profil-image.jpg"/>
            </div>
        )
    }
}

import React from "react";

export default class CompetencesHeader extends React.Component {
    render() {
        return(
            <div id="header" className="flex">
                <div className="row-first-column"></div>
                <div id="reguliere" className="row-title first">
                    <h3>Régulières</h3>
                    <div className="line-separator horizontal green"></div>
                </div>
                <div id="occasionnel" className="row-title">
                    <h3>Occasionnelles</h3>
                    <div className="line-separator horizontal blue"></div>
                </div>
                <div className="row-title third">
                    <h3>Ponctuelles</h3>
                    <div className="line-separator horizontal"></div>
                </div>
            </div>
        )
    }
}

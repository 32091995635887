import React from "react";
import ProfilImage from "./ProfilImage";
import ProfilDescription from "./ProfilDescription";

export default class Profil extends React.Component<ProfilInterface> {
    render() {
        return (
            <div className={"flex"}>
                <ProfilImage />
                <ProfilDescription informations={this.props.informations} />
            </div>
        )
    }
}

interface ProfilInterface {
    informations: Informations;
}

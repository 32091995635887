import React from "react";
import Profil from "./profil/Profil";
import Competences from "./comptences/competences";
import Experiences from "./Experiences/Experiences";
import Interests from "./Interests/Interests";
import Certificates from "./Certificates/Certificates";

export default class DataLoader extends React.Component {

    state = {
        resume: {skills: [], references: [], projects: [], interests: [], basics: {}, certificates: []} as Resume,
        loaded: false
    }

    updateData = () => {
        fetch('resume.json').then(response => {
            return response.json()
        }).then((data: Resume) => {
            console.log(data);
            this.setState({
                resume: data,
                loaded: true
            })
        })
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        if(this.state.loaded) {
            return (
                <div>
                    <Profil informations={this.state.resume.basics}/>
                    <Competences skills={this.state.resume.skills}/>
                    <Certificates certificates={this.state.resume.certificates}/>
                    <Experiences experiences={this.state.resume.references} skills={this.state.resume.skills.flatMap((entry) => entry.items)} cssId={"experiences"} title={"Expériences"}/>
                    <Experiences experiences={this.state.resume.projects} skills={this.state.resume.skills.flatMap((entry) => entry.items)} cssId={"projets"} title={"Projets"}/>
                    <Interests interests={this.state.resume.interests}/>
                    <div className={"information-update"}>Dernière mise à jour : { process.env.LAST_BUILD }</div>
                </div>
            )
        }

        return (<div>
            Chargement du CV...
        </div>)
    }

}

import React from "react";

export default class CompetenceLevel extends React.Component<CompetenceLevelInterface> {

    getEmptyPrefixClassName = () => {
        if(this.props.skillItem.items.length == 0){
            return "row-empty "
        }
        return "";
    }
    
    getCurrentColumn = () => {
        switch (this.props.skillItem.level) {
            case "REGULAR" :
                return this.getEmptyPrefixClassName() + "first"
            case "SOMETIMES" :
                return this.getEmptyPrefixClassName() + ""
            case "OLD" :
                return this.getEmptyPrefixClassName() + "third"
        }
    }

    getCurrentColor = () => {
        switch (this.props.skillItem.level) {
            case "REGULAR" :
                return "green"
            case "SOMETIMES" :
                return "blue"
            case "OLD" :
                return ""
        }
    }

    render() {
        return (
            <div className={"flex row " + this.getCurrentColumn()}>
                {
                    this.props.skillItem.items
                        .sort(function (a, b) {
                            return a.toLowerCase().localeCompare(b.toLowerCase());
                        })
                        .map(item => <div className={"tag " + this.getCurrentColor()}>{item}</div>)
                }
            </div>
        )
    }
}

interface CompetenceLevelInterface {
    skillItem: SkillItem
}
